// import Storage from "vue-ls";

// const options = {
//     namespace: 'x_mgr_vuejs__',
//     name: 'ls',
//     storage: 'local',
// }

// const { ls } = Storage.useStorage(options);
// export default ls;

import { useStorage } from "vue3-storage";


export const expire = 1000 * 60 * 60;

const key = 'Authorization_Token';
export const AppKeys = {
    UserInfo: 'UserInfo',
    MenuInfo: 'MenuInfo',
    ChannelInfo: 'ChannelInfo'
};


export const storeage = useStorage();

export function setAuthorizationToken(val: any) {
    removeAuthorizationToken();
    storeage.setStorageSync(
        key,
        val,
        expire
    );
}

export function removeAuthorizationToken() {
    storeage.removeStorageSync(
        key
    );
}

export function getAuthorizationToken() {
    return storeage.getStorageSync(
        key
    );
}

export function setStorageSync(key: string, val: any, expire?: number | undefined) {
    storeage.setStorageSync(
        key,
        val,
        expire
    );
}

export const clearStorageSync = () => {
    storeage.clearStorageSync();
}

export function getStorageSync(key: string) {
    return storeage.getStorageSync(key)
}