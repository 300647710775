// import {defineStore} from 'pinia';
// // import {storage} from '@/utils/Storage';

// export type ICommonHelperConfig={
//     collapseState:boolean
// }

// export const useCommonHelperConfigStore = defineStore( {
//         id:'x-commonHelper',
//         state:():ICommonHelperConfig=>({
//             collapseState :false
//         }),
//         actions:{
//             changeCollapseState(){
//                 this.collapseState =!this.collapseState
//             }
//         }
//     }
// ) 

import {Module} from 'vuex';
import RooStateTypes from '../interface';


export default interface ICommonHelperConfigTypes extends RooStateTypes{
    collapseState:boolean;
}



export const headerModule:Module<ICommonHelperConfigTypes,RooStateTypes> = {
    namespaced:process.env.NODE_ENV !== 'production',
    state:{
        collapseState:false,
        username:'',
        loaded:0,
        documentTitle:'',
    },
    mutations:{
        changeCollapseState(state){
            state.collapseState=!state.collapseState;
        },
        changeTitle(state,title){
            state.documentTitle = title;
        }
    }
}