
import { InjectionKey } from 'vue';
import { createStore, Store, useStore as baseUseStore } from 'vuex';
import RooStateTypes, { AllStateTypes } from './interface';
import { headerModule } from './modules/header';
import { getStorageSync, AppKeys } from '@/utils/LocalStorage'

interface MenuModel {
  path: string;
  name: string;
  component: () => Promise<any>;
  children: MenuModel[];
}


export default createStore<RooStateTypes>({
  state: {
    username: '',
    loaded:0,
    documentTitle:'',
  },
  getters: {
    getTitle(state){
      return state.documentTitle;
    }
  },
  mutations: {
    addLoaded(state){
      state.loaded=1;
    },
    setDocTitle(state,title){
      state.documentTitle = title;
    },
    clearRouters(state, router) {
      const menus = getStorageSync(AppKeys.UserInfo);
      if (menus) {
        for (const menu of menus.permissions) {
          router.removeRoute(menu.name);
          for (const subMenu of menu.children) {
            router.removeRoute(subMenu.name);
            for (const subMenu1 of subMenu.children) {
              router.removeRoute(subMenu1.name);
            }
          }
        }
      }
    },
    addRouters(state, router) {
      const menus = getStorageSync(AppKeys.UserInfo);
      if (menus) {
        // let newMenuList: MenuModel[] = [];
        for (const menu of menus.permissions) {
          
          let newMenu = {
            id:menu.id,
            path: menu.path,
            name: menu.name,
            meta:menu.meta,
            component: () => import(`@/views${menu.component}`),
            children: [] as any[],
          }
          // router.addRoute(newMenu);       
          if (menu.children.length > 0) {
            for (const subMenu of menu.children) {
              let subNewMenu = {
                id:subMenu.id,
                path: subMenu.path,
                name: subMenu.name,
                meta:subMenu.meta,
                component: () => import(`@/views${subMenu.component}`),
                children: [] as any[],
              }


              if (subMenu.children.length > 0) {
                for (const subMenu1 of subMenu.children) {
                  let subNewMenu1 = {
                    id:subMenu1.id,
                    path: subMenu1.path,
                    name: subMenu1.name,
                    meta:subMenu1.meta,
                    component: () => import(`@/views${subMenu1.component}`),
                    children: [] as any[],
                  }
                  
                  // if(router.hasRoute(subNewMenu.name)){
                  //   router.removeRoute(subNewMenu.name);
                  // }
                  // router.addRoute(newMenu.name,subNewMenu);
                  subNewMenu.children.push(subNewMenu1);
                }              
              }


              // if(router.hasRoute(subNewMenu.name)){
              //   router.removeRoute(subNewMenu.name);
              // }
              // router.addRoute(newMenu.name,subNewMenu);
              newMenu.children.push(subNewMenu);
            }              
          }
          // if(router.hasRoute(newMenu.name)){
          //   router.removeRoute(newMenu.name);
          // }
          router.addRoute(newMenu);       
        }

      }
    }
  },
  actions: {
  },
  modules: {
    headerModule
  }
});

export const key: InjectionKey<Store<RooStateTypes>> = Symbol('x-vue-store');

export function useStore<T = AllStateTypes>() {
  return baseUseStore(key);
}
