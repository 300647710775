import { Chicken } from '@element-plus/icons-vue/dist/types'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

export const  LAYOUT= '/main/MainView.vue';
const home ='/dashboard/ovpage/overview.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'm',
    component: () => import(`@/views${LAYOUT}`),
    redirect: '/login'
  },
 

  {
    path: '/main',
    name: 'main',
    component: () => import(`@/views${LAYOUT}`),
    redirect:'/main/home',
    children:[
      {
        path: 'home',
        name: 'home',
        component: () => import(`@/views${home}`),
      },
    ]
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes:routes,
})

export default router
